<template>
  <div class="check-in-wrapper">
    <Header/>
    <PageLayout class="mt-0 px-0">
      <div class="content h-100">
        <ProgressLine total-step="7"
                      :progress="Number($route.name) ?  $route.name*1: 7"/>
        <div class="step"><span>{{ $t('counterTitle') }}</span> {{ $route.name }}/7</div>
        <router-view/>
      </div>
      <ResetModal @close-modal="isModal = false" v-if="isModal"/>
    </PageLayout>
  </div>
</template>

<script>
import ProgressLine from "../components/ProgressLine";
import Header from "../components/Header";
import ResetModal from "../components/RestartModal";

export default {
  name: "CheckIn",
  components: {
    ProgressLine,
    Header,
    ResetModal
  },
  data() {
    return {
      isModal: false
    }
  },
  created() {
    this.$root.$on('reset-modal', state => {
      this.isModal = state
    })
    if (!sessionStorage.getItem(this.$route.name) && this.$route.name !== '1') this.$router.push('/')
  },
  destroyed() {
    this.$root.$off('reset-modal')
  },
}
</script>

