<template>
  <div class="restart-wrapper-modal" @click.self="closeModal">
    <div class="restart-content">
      <div class="title">{{ $t('restartModal.title') }}</div>
      <p class="mb-0" v-html="$t('restartModal.description')"/>
      <div class="d-flex justify-content-center">
        <div class="btn" @click="closeModal">{{ $t('restartModal.btn_1') }}</div>
        <div class="btn" @click="$router.push('/')">{{ $t('restartModal.btn_2') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestartModal",
  methods: {
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>
