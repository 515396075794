<template>
  <div class="position-relative clip d-flex">
    <img :style="rect" :src="img">
    <img class="opacity" :src="img">
  </div>
</template>

<script>
export default {
  name: "ProgressLine",
  props: {
    progress: {
      required: true,
      type: Number
    },
    totalStep: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      img: require('../assets/img/progress-line/progress-bar-progress.svg'),
      rect: '',
      lineWidth: 0
    }
  },
  mounted() {
    this.lineWidth = document.querySelector('.clip').offsetWidth;
    this.setClipPass(this.progress);
  },
  methods: {
    setClipPass(progress) {
      let width = this.lineWidth / (Number(this.totalStep) / +progress)
      if (window.innerWidth > 767) this.rect = `clip:rect(0, ${width}px, 4px, 0);`
      else {
        this.img = require('../assets/img/progress-line/progress-bar-progress2.svg')
        this.rect = `clip:rect(0, ${width}px, 10px, 0);`
      }
    }
  },
  watch: {
    '$route': function () {
      this.setClipPass(this.progress);
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  position: absolute;
  top: 0;
  width: 100%;
  @media (max-width: 767px) {
    height: 10px;
  }
  &:first-of-type {
    z-index: 1;
    transition: all .3s linear;
  }
}

.opacity {
  opacity: 0.1;
}
</style>
