<template>
  <div class="header-wrapper">
    <div class="d-flex align-item-center custom-position">
      <img width="34" class="back-btn" @click="goTo" src="../assets/img/header/back-copy.svg" alt="">
      <h2 class="main-title">{{ staticText ? staticText : $t('questions.mainTitle_1') }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ['staticText'],
  methods: {
    goTo() {
      if (this.$route.name === '5') {
        if (sessionStorage.getItem('isPoint') === 'true') this.$router.go(-1);
        else if (!sessionStorage.getItem('isPoint')) this.$router.go(-1);
        else this.$root.$emit('isPoint', true)
      } else {
        this.$router.go(-1);
      }
    }
  }
}
</script>
